/* ==========================================================================
Utilities
  > Backgrounds
========================================================================== */

// Dark bgs

.u-bg-color--primary,
.u-bg-color--primary--dark,
.u-bg-color--text {
  @include t-theme--bg-dark--surface--light;
}

.u-bg-color--primary--lightest {
  background-color: var(--color--primary--lightest) !important;
}

.u-bg-color--primary {
  background-color: var(--color--primary) !important;
}

.u-bg-color--primary--dark {
  background-color: var(--color--primary--dark) !important;
}

.u-bg-color--secondary {
  background-color: var(--color--secondary) !important;
}

.u-bg-color--tertiary {
  background-color: var(--color--tertiary) !important;
}

// White

.u-bg--white,
.u-bg-color--white {
  background-color: #fff;
}

// Bg (Text)

.u-bg-color--text {
  background-color: var(--color--text) !important;
}

// Stretch

.u-bg-color--stretch {
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    width: 3000px;
    height: 100%;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3000px;
  }

  &:after {
    right: -3000px;
  }

  &.u-bg-color--primary--lightest {
    &:before,
    &:after {
      background-color: var(--color--primary--lightest) !important;
    }
  }

  &.u-bg-color--primary {
    &:before,
    &:after {
      background-color: var(--color--primary) !important;
    }
  }

  &.u-bg-color--primary--dark {
    &:before,
    &:after {
      background-color: var(--color--primary--dark) !important;
    }
  }

  &.u-bg-color--text {
    &:before,
    &:after {
      background-color: var(--color--text) !important;
    }
  }
}

// ============ Fit to Container

// Bg Images - Cover

.has--bg--img {
  @include bp-small {
    @include t-bg--img;
    padding: var(--wrapper__padding);
  }

  @include bp-max-small {
    background-image: none !important;
  }
}
