/* ==========================================================================
Utilities
  > Flex
========================================================================== */

.u-flex {
  display: flex;
}

.u-flex--wrap {
  @include t-flex--wrap;
}

.u-flex--col {
  flex-direction: column;
}

.u-flex--align--center {
  align-items: center;
}

.u-justify--center {
  justify-content: center;
}

.u-justify--start {
  justify-content: start;
}

.u-justify--end {
  justify-content: end;
}

.u-justify--sb {
  justify-content: space-between;
}

// @sm

@include bp-small-medium {
  .u-flex--sm {
    display: flex;
  }

  .u-flex--wrap--sm {
    flex-wrap: wrap;

    .col.sm6 {
      margin-left: 0;
    }
  }
}

// @m

@include bp-medium {
  .u-flex--m {
    display: flex;

    .c-card {
      height: 100%;
    }
  }
  // Wrap

  .u-flex--wrap--m {
    flex-wrap: wrap;

    .col.m6 {
      margin-left: 0;
    }
  }
}

// @ml

@include bp-medium-large {
  .u-flex--ml {
    display: flex;

    .c-card {
      height: 100%;
    }
  }
}

// @l

@include bp-large {
  .u-flex--l {
    display: flex;

    .c-card {
      height: 100%;
    }
  }

  .u-align--flex--end--xl {
    align-items: flex-end;
  }

  .u-flex--row--l {
    flex-direction: row;
  }
}

// @xl

@include bp-xlarge {
  .u-flex--xl {
    display: flex;

    .c-card {
      height: 100%;
    }

    .col.xl6 {
      margin-left: 0;
    }
  }

  // Wrap

  .u-flex--wrap--xl {
    flex-wrap: wrap;

    .col.l4 {
      margin-left: 0;
    }
  }
}

.u-flex--gap--16 {
  gap: 16px;
}

// Order

.u-order--2 {
  order: 2;
}
