/* ==========================================================================
Utilities
  > Borders
========================================================================== */

.u-b--t {
  border-top: 1px solid var(--color--border);
}

.u-b--t--3--white {
  border-top: 24px solid #fff;
}

.u-b--r {
  border-right: 1px solid var(--color--border);
}

.u-b--b {
  border-bottom: 1px solid var(--color--border);
}

.u-b--l {
  border-left: 1px solid var(--color--border);
}
