/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.5rem;
}

code {
  display: flex;
  background-color: var(--color--grey--lighter);
  border-radius: var(--global__radius);
  padding: 24px;
  white-space: nowrap;
  font-size: 15px;
  line-height: 1.8;
  width: 100%;
  overflow-x: auto;

  pre {
    margin: 0;
  }

  @include bp-medium {
    padding: 40px;
  }

  .w-widgets & {
    @include bp-xlarge {
      max-width: calc(66.666% - (var(--gutter) / 2));

      .l8 & {
        max-width: none;
      }
    }
  }
}

/**
 * Consistent indentation for lists.
 */

dd,
ol,
ul {
  margin-left: 1rem;
}
