/* ==========================================================================
Components
  > Restricted
========================================================================== */

.c-restricted--video {
  @include t-theme--bg-dark--surface--light;
  background-color: var(--color--primary--dark);
  aspect-ratio: 16/9;
  display: flex;
  margin-bottom: 1.5rem;
  padding: 32px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.c-restricted__content {
  z-index: 3;

  h2 {
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .c-btn {
    margin-top: 24px;
  }
}
