/* ==========================================================================
Components
  > Modals
========================================================================== */

// Hold

.c-modal {
  display: none;
  opacity: 0;
  transition: opacity 0.15s linear;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.show-modal {
    display: block;
    opacity: 1;
    // padding-right: 17px;

    .c-modal__dialog {
      transform: translate(0, 0);
    }
  }

  // Bg

  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.32);
    position: fixed;
    width: 100%;
    height: 100%;
  }

  // Inline

  .c-modal__dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
    transition: transform 0.3s ease-out;

    @include bp-xsmall {
      margin: 16px;
    }

    @include bp-medium {
      margin: var(--gutter);
    }
  }

  // Content

  .c-modal__content {
    @include shadow(4);
    border-radius: var(--global__radius);
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    position: relative;
    background: #fff;
    width: auto;
    max-width: 576px;
    margin: 0 auto;
    padding: 24px;

    @include bp-small-medium {
      padding: 48px;
    }

    // Close

    .c-btn--close {
      border-top-right-radius: var(--global__radius);
      border-bottom-left-radius: var(--global__radius);
    }

    // Ok

    .c-btn--ok {
      margin-top: auto;
    }

    // Article

    article {
      max-width: calc(100% - var(--tap-target__size));
      margin-bottom: var(--spacer);

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Heading

    @include bp-max-medium {
      .h4 {
        font-size: 18px;
      }
    }
  }

  // Modal - Side

  &.c-modal--side {
    .c-modal__dialog {
      //height: 100%;
      //  min-height: 100%;
      margin: 0;

      .c-modal__content {
        border-radius: 0;
        margin: 0;
        padding: 48px;
        width: 270px;
        max-width: none;
        //height: 100%;
        //min-height: 100%;

        .c-modal__dialog {
          margin: 0;
          height: 100%;
        }

        .c-btn--close {
          border-radius: 0;
        }
      }
    }

    // Left

    &.c-modal--side--l {
      .c-modal__dialog {
        transform: translateX(-100%);
        min-height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .c-modal__content {
          flex: 1;
        }
      }

      /*
      &.show-modal {
        .c-modal__dialog {
          transform: translateX(0);
        }
      }
      */
    }

    // Right

    &.c-modal--side--r {
      .c-modal__dialog {
        align-items: flex-start;
        justify-content: flex-end;
      }
    }
  }

  // Mobile Menu

  &.c-modal--menu {
    .c-modal__dialog,
    .c-modal__content {
      height: 100%;
      box-shadow: none;
    }
  }

  // Nav

  nav {
    margin: -48px;
    padding-top: var(--tap-target__size);

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        button {
          @include t-reset--btn;
          color: var(--color--primary);

          &.is--visible__sub {
            + ul {
              max-height: 2000px;
            }

            [class^='moon-']:before {
              content: '\e912';
            }
          }
        }

        a,
        button {
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          min-height: var(--tap-target__size);
          border-bottom: 1px solid var(--color--grey--light);
          padding: 16px 24px;

          [class^='moon-'] {
            font-size: 10px;
            margin-left: auto;
            pointer-events: none;
          }

          .moon-external-link {
            font-size: 16px;
            transform: translateX(4px);
            color: var(--color--grey--dark);
          }

          &:hover {
            background: var(--color--grey--light);

            .moon-external-link {
              color: var(--color--primary);
            }
          }
        }

        ul {
          transition: all 0.2s ease-in-out;
          transform: translateZ(0);
          max-height: 0;
          overflow: hidden;

          li a {
            padding-left: 40px;
          }
        }
      }
    }
  }
}

// Active - html

.modal--active {
  body {
    overflow: hidden;
    // padding-right: 17px;
  }

  #c-btn--trigger {
    background: var(--color--primary);
    border: 1px solid var(--color--primary);
    color: #fff;
  }
}

// Trigger

.c-btn--modal {
  @include t-reset--btn;

  &:hover {
    text-decoration: underline;
    color: var(--color--primary);
  }

  &:focus-visible {
    outline: 2px dashed var(--color--primary);
  }
}

// Trigger - In List

.o-list-bare {
  .c-btn--modal {
    text-align: left;
  }
}
