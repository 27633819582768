/* ==========================================================================
Tools
  > Lists
========================================================================== */

// Bullet Custom

@mixin t-list--bullet-custom {
  list-style: none;
  margin-left: 0;
  margin-bottom: 40px;

  @include bp-medium {
    margin-bottom: 72px;
  }

  li {
    position: relative;
    margin-bottom: 12px;
    padding-left: 24px;

    &:before {
      content: '';
      background: #444;
      width: 3px;
      height: 3px;
      display: inline-block;
      position: absolute;
      top: 14px;
      left: 0;
    }

    a {
      color: var(--color--text);

      &:hover {
        color: var(--color--primary);
        text-decoration: underline;
      }

      &:focus-visible {
        outline-offset: 2px;
      }
    }
  }
}

// Sort / Delete (e.g. Clipboard)

@mixin t-list--sort--delete {
  .c-article {
    display: flex;
    flex-direction: column;
    background-color: var(--color--grey--lighter);
    border-radius: var(--global__radius);
    border: 2px solid transparent;
    padding: var(--wrapper__padding--minor);

    @include bp-small {
      flex-direction: row;
    }

    &:hover {
      outline: 1px solid var(--color--primary);

      @include bp-large {
        .c-article__actions {
          opacity: 1;
        }
      }
    }

    @include bp-large {
      &:focus-within {
        .c-article__actions {
          opacity: 1;
        }
      }
    }

    .c-link--cover {
      &:hover {
        color: var(--color--primary);
        text-decoration: none !important;
      }

      &:focus {
        color: var(--color--primary);

        &:after {
          border-radius: var(--global__radius);
        }
      }
    }
  }

  .c-article__actions {
    @include bp-max-large {
      opacity: 1;
    }

    opacity: 0;
    display: flex;
    align-self: center;
    border: 1px solid var(--color--primary);
    border-radius: var(--global__radius);
    z-index: 3;
    margin-top: 24px;

    @include bp-small {
      flex-direction: column;
      margin-top: 0;
      margin-left: 24px;
    }

    button {
      @include t-reset__btn;
      width: var(--tap-target__size);
      height: var(--tap-target__size);

      &:nth-child(1) {
        border-radius: var(--global__radius) 0 0 var(--global__radius);

        @include bp-small {
          border-radius: var(--global__radius) var(--global__radius) 0 0;
        }
      }

      &:nth-child(2) {
        border-left: 1px solid var(--color--primary);
        border-right: 1px solid var(--color--primary);

        @include bp-small {
          border-left: none;
          border-right: none;
          border-top: 1px solid var(--color--primary);
          border-bottom: 1px solid var(--color--primary);
        }
      }

      &:nth-child(3) {
        border-radius: 0 var(--global__radius) var(--global__radius) 0;

        @include bp-small {
          border-radius: 0 0 var(--global__radius) var(--global__radius);
        }
      }

      &:hover,
      &:focus {
        background-color: #fff;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

// Cards

@mixin t-list--cards {
  display: grid;
  gap: var(--gutter);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: 40px;

  @include bp-small {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include bp-medium-large {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include bp-xlarge {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  li {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color--primary--dark);
    border-radius: var(--global__radius);
    padding: 32px;
  }

  a:focus-visible:after {
    outline-offset: 2px;
  }
}

// Cards - Main - 7

@mixin t-list--cards--main--7 {
  @include bp-large {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include bp-xxlarge {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

// Cards - Sidebar - 5

@mixin t-list--cards--sidebar--5 {
  @include bp-max-medium-large {
    li {
      // flex-direction: row-reverse;
    }
  }

  @include bp-medium-large {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include bp-large {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @include bp-xlarge {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* Areas (Periodic Table style)
  e.g.

  Ma
  Mathematics
*/

@mixin t-list--areas {
  li {
    position: relative;
    overflow: hidden;
  }

  .c-area__symbol {
    @include t-heading--base;
    font-size: unquote('clamp(2rem, 1.3333rem + 3.3333vw, 4rem)');

    line-height: 1;
    letter-spacing: -1.28px;
  }

  .c-area__name {
    margin-top: auto;
    padding-top: 8px;
  }

  a {
    &:hover,
    &:focus-visible {
      + .c-area__symbol,
      ~ .c-area__name {
        color: #fff;
        pointer-events: none;
        z-index: 3;
      }

      &:after {
        border-radius: var(--global__radius);
        scale: 1.1;
        background-color: var(--color--primary--dark);
      }
    }
  }
}

@mixin t-list--areas--main--7 {
  @include bp-xxlarge {
    .c-area__symbol {
      font-size: 64px;
    }
  }
}

// Horizontal

@mixin t-list--horizontal {
  flex-wrap: wrap;
  flex-direction: column;

  @include bp-small-medium {
    flex-direction: row;
  }
}

// Btns

@mixin t-list--btns {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;

  .c-cta-banner & {
    width: 100%;
    margin-top: 0;
  }
}

// Btns - Sector

@mixin t-list--btns--sector {
  justify-content: center;

  li {
    flex-shrink: 0;
  }
}

// Boxed

@mixin t-list--boxed {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color--primary);
  border-radius: var(--global__radius);
  overflow: hidden;

  @include bp-medium {
    flex-direction: row;
  }

  li {
    &:not(:first-child) {
      border-top: 1px solid var(--color--primary);

      @include bp-medium {
        border-top: none;
        border-left: 1px solid var(--color--primary);
      }
    }
  }

  a {
    @include t-heading--h4;
    color: var(--color--text);
    display: flex;
    padding: 32px;
    justify-content: center;
    text-align: center;
    text-decoration: none !important;

    &:hover,
    &:focus-visible {
      outline: 0;
      color: #fff !important;
      background-color: var(--color--primary);
    }
  }
}
