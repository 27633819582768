/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.btn,
.c-btn {
  @include t-btn;
}

// .c-btn {
//   &:not(.c-btn--outline):not(.close-button--mega) {
//     color: #fff !important;
//   }
// }
// Secondary

.c-btn--secondary {
  background: var(--color--secondary);
}

// White

.c-btn--white {
  background: #fff;
  color: var(--color--text);

  &:hover {
    background: var(--color--primary);
  }
}

// Outline

.c-btn--outline {
  @include t-btn--outline;

  // Outline - Light

  &.c-btn--outline--light {
    @include t-btn--outline--light;
  }
}

ul[data^='data-listing__cols'] {
  display: grid;
}

.c-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  ul {
    list-style: none;
    margin: 0;

    @include bp-max-small {
      width: 100%;
    }

    &:not([data-listing__cols]) {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    &[data-listing__cols] {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 16px;
      width: 100%;

      .c-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    }

    @include bp-small-medium {
      &[data-listing__cols='2'],
      &[data-listing__cols='3'],
      &[data-listing__cols='4'] {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    @include bp-medium-large {
      &[data-listing__cols='3'],
      &[data-listing__cols='4'] {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    @include bp-xlarge {
      &[data-listing__cols='4'] {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }

    @include bp-max-small {
      li {
        width: 100%;
      }
    }

    @include bp-large {
      &[data-listing__cols='3'],
      &[data-listing__cols='4'] {
        .o-sidebar & {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }

  + h2,
  + p,
  + ul,
  + ol {
    margin-top: 40px;
  }
}

// Inline

.c-btns--inline {
  .c-btn {
    margin-right: 8px;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0 16px;
}

/* Width variants
   ========================================================================== */

.u-btn--full {
  width: 100% !important;
}

// Footer Button

.c-footer__btn {
  border-top: 1px solid var(--color--grey--light);
  padding-top: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// Arrow Button

.c-btn--arrow {
  color: var(--color--primary) !important;
  background: none;
  padding: 0;
  border: 0;
  font-size: 16px;
  position: relative;
  transition: 0.3s all ease-in-out;
  font-weight: normal;
  overflow: visible;
  white-space: normal;
  text-align: left;
  height: auto;
  line-height: 1.4;
  overflow-wrap: break-word;

  span {
    margin-left: 4px;
    transition: var(--global__transition);
  }

  &:hover {
    background: transparent;
    border: none;

    span {
      margin-left: 8px;
    }
  }
}

// Extend

.c-btn--extend {
  width: 100%;
  margin: 0;
  display: block;
}

// No Case

.c-btn--case--none {
  text-transform: none;
}

// Weight - 400

.c-btn--weight--400 {
  font-weight: 400;
}

// Meta

.c-btn__meta {
  display: inline-block;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.33);
  padding-left: 12px;
  margin-left: 12px;
}

// Icon

.c-btn__icon {
  display: inline-block;
  margin-right: 4px;
}

// Disabled

.c-btn:disabled {
  opacity: 0.48;
}

.c-filter__btn:disabled {
  opacity: 0;
}

// Close

[class*='c-btn--close'] {
  @include t-reset--btn;
  width: var(--tap-target__size);
  height: var(--tap-target__size);
  line-height: var(--tap-target__size);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 24px;

  &:focus-visible {
    color: #fff;
    background: var(--color--primary);
  }
}

// Label - Close

.c-btn--label-close {
  @include t-reset--btn;
  background-color: #ebebeb;
  padding-left: 2px;
  padding-right: 2px;
  border-left: 2px solid #fff;
  text-align: center;
}

// Trigger

.c-btn--trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 80px;
  height: var(--header__height);

  @include bp-large {
    height: 80px;
  }

  .c-btn__icon {
    font-size: 24px;
    margin-right: 8px;
  }
}

// Min 256

.c-btn--min--256 {
  min-width: 256px;
}

// Download

.c-btn--download {
  margin-top: 24px;
  margin-right: auto;

  .far fa-chevron-down {
    font-size: 14px;
  }

  @include bp-medium {
    margin-left: auto;
    margin-right: 0;
    padding-left: 32px;
  }
}

// Plain

.c-btn--plain {
  background: transparent;
  color: var(--color--primary);
  padding: 0;
  height: auto;
  line-height: normal;
  white-space: normal;

  &:hover {
    background: transparent;
    color: var(--color--primary);
    text-decoration: underline !important;
  }
}

// Icon

.c-btn--icon {
  @include t-reset--btn;
  display: grid;
  place-content: center;
  width: var(--tap-target__size);
  height: var(--tap-target__size);
  color: var(--color--primary);
  border-radius: 8px;

  span {
    font-size: 24px;
  }

  @media (hover) {
    &:hover {
      background-color: var(--color--primary);
      color: #fff;
    }
  }
}

.u-bg-color--text {
  .c-btn:focus {
    outline-color: rgba(255, 255, 255, 0.86);
  }
}

// Remove defaults

.c-btn--remove-default {
  @include t-btn--remove-default;
}

// Absolute

.c-btn--absolute {
  position: absolute;
  top: 0;
  right: 14px;
}

// Close - Mega

.close-button--mega {
  .fal {
    position: relative;
    top: 2px;
    margin-left: 4px;
  }

  &:focus-visible {
    outline-color: var(--color--primary);
  }
}

// Container

.c-btn--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .c-btn {
    margin: 10px;
  }
}

// Sectors

.c-btn--sector--early-childhood {
  color: var(--color--sector--early-childhood);
  border-color: var(--color--sector--early-childhood);

  &:hover {
    background-color: var(--color--sector--early-childhood);
  }
}

.c-btn--sector--primary {
  color: var(--color--sector--primary);
  border-color: var(--color--sector--primary);

  &:hover {
    background-color: var(--color--sector--primary);
  }
}

.c-btn--sector--junior-cycle {
  color: var(--color--sector--junior-cycle);
  border-color: var(--color--sector--junior-cycle);

  &:hover {
    background-color: var(--color--sector--junior-cycle);
  }
}

.c-btn--sector--senior-cycle {
  color: var(--color--sector--senior-cycle);
  border-color: var(--color--sector--senior-cycle);

  &:hover {
    background-color: var(--color--sector--senior-cycle);
  }
}
