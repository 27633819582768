/* ==========================================================================
Elements
  > Description Lists
========================================================================== */

dl {
  dt {
    color: var(--color--primary);
    font-weight: 500;
  }
}
