/* ==========================================================================
Objects
  > Content Area
      (WYSIWYGs)
========================================================================== */

.c-article__body {
  > * {
    margin-top: 48px;
    margin-bottom: 0;
  }

  > :nth-child(1) {
    margin-top: 0;
  }

  // h1 {
  //   font-size: 40px;
  //   margin-bottom: 32px;
  // }

  h2,
  h3,
  p {
    + p {
      margin-top: 24px;
    }
  }

  .c-article__listing {
    p {
      margin-top: 0;
    }
  }

  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6 {
    margin-top: 24px;
  }

  ul:not(.c-list--cards):not(.c-article__listing):not([data-listing__cols]),
  ol {
    margin-left: 24px;

    li:not(:first-child) {
      margin: 8px 0 0;
    }
  }

  iframe {
    aspect-ratio: 16 / 9;
    height: auto;
  }
}
