/* ==========================================================================
Components
  > Header 
========================================================================== */

// Skip to Content

.skip-to-content-link {
  background: var(--color--primary);
  height: 40px;
  left: 50%;
  top: 0px;
  padding: 8px;
  position: absolute;
  transform: translate(-50%, -1000px);
  transition: transform 0.3s;
  z-index: 999;
  color: #fff;
  font-size: 18px;

  &:focus-visible {
    transform: translate(-50%, 0%);
  }

  &:hover,
  &:active {
    color: #fff;
  }
}

// Header

.c-header {
  width: 100%;
  margin: 0;
  background: #fff;

  .c-header-top {
    background-color: var(--color--primary);
    transition: all 400ms;
    display: flex;
    flex-direction: column;
    padding: 16px var(--gutter);

    @media (max-width: 640px) {
      position: relative;
    }

    @media (min-width: 640px) {
      height: var(--header__height);
      flex-direction: row;
      align-items: center;
      padding-top: 0;
      padding-bottom: 0;
    }

    // @include bp-large {
    //   padding: 0 64px;
    // }
  }

  // Logo

  .c-logo {
    display: flex;
    align-self: flex-start;
    flex-shrink: 0;
    margin-right: var(--gutter);
    z-index: 1;
    height: 36px;

    @media (min-width: 640px) {
      align-self: center;
    }

    @include bp-large {
      height: 48px;
    }

    img {
      width: auto;
      height: 100%;
    }

    &:focus-visible {
      outline-offset: 8px;
      outline-color: #fff;
      border-radius: 1px;
    }
  }

  // Fixed
  &.c-header--fixed {
    position: fixed;
    transition: transform 400ms;
    z-index: 10;
    right: 0;
    left: 0;
    top: 0;

    @media (min-width: 1280px) and (max-height: 900px) {
      position: absolute;
    }
  }
}

// Main Menu Wrapper

.c-header__controls {
  display: flex;
  height: var(--header__height);
  line-height: var(--header__height);
  margin-left: auto;

  @include bp-large {
    margin: 0;
  }
}

// Buttons

.c-btn--header {
  @include t-btn--header--base;

  .c-btn__icon {
    @include t-btn--header__icon;
  }
}

// Site Search

.c-site-search {
  position: relative;

  &.c-search--primary {
    width: 100%;
    margin-top: 16px;

    @media (min-width: 640px) {
      min-width: 294px;
      max-width: 294px;
      margin-top: 0;
      margin-left: auto;
    }

    @include bp-xxlarge {
      max-width: 320px;
    }

    input[type='text'] {
      font-size: 18px;
      padding-top: 12px;
      padding-right: calc(var(--main-search__button__width) + 8px);
      background: #fff;
      height: var(--main-search__input__height);

      &:focus-visible {
        outline: 2px solid #fff;
        outline-offset: 2px;
      }
    }

    button {
      font-size: 18px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      cursor: pointer;
      border: none;
      border-radius: 0 var(--global__radius) var(--global__radius) 0;
      color: var(--color--text);
      background: #fff;
      width: var(--main-search__button__width);
      height: var(--main-search__input__height);
      line-height: var(--main-search__input__height);

      &:focus-visible {
        outline: 2px solid #fff;
        outline-offset: 2px;
      }
    }
  }
}

// Utility Menu

.c-utility-menu-container {
  display: flex;
  transition: opacity 320ms ease-in 128ms;
  width: 100%;
  height: var(--utility-menu__height);
  padding-inline: var(--gutter);
  width: auto;

  @include bp-max-large {
    padding-left: 0;
  }

  .o-container {
    display: flex;
    align-items: center;
    max-width: none;
    margin: 0;
    padding: 0;

    @include bp-large {
      justify-content: flex-end;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }
}

.c-utility-menu {
  flex-shrink: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  margin: 0;

  &:last-child {
    padding-left: 16px;
  }

  &:not(:last-child) {
    padding-inline: 16px;
  }

  &:not(:first-child) {
    border-left: 1px solid var(--color--border);
  }

  li {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    font-size: 15px;

    a {
      display: flex;
      align-items: center;
      color: var(--color--text);
      font-family: var(--font--body);

      &:hover {
        text-decoration: none !important;
        color: var(--color--primary);
      }
    }
  }
}

// Utility Menus

.c-site-dropdowns {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: auto;
}

.c-sites-dropdown {
  display: inline-flex;
  align-items: center;
  height: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;
  margin: 0 16px 0 0;

  &.active {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: var(--color--primary);
    }
  }

  a {
    display: flex;
    height: 100%;
    align-items: center;
    color: var(--color--text);
    font-size: 16px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    color: var(--color--text);
    font-size: 12px;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;

    &:hover {
      text-decoration: none;
      color: #000;
    }

    .far {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    &:focus-visible {
      outline: none;

      .far {
        outline: 2px solid var(--color--primary);
        border-radius: 100%;
        outline-offset: -7px;
      }
    }
  }
}

.c-sites-dropdown {
  > ul {
    @include t-drop-menu--ui;
    top: var(--tap-target__size);
    left: 0;
  }

  button[aria-expanded='true'] {
    .far {
      transform: rotate(180deg);
    }
  }
}

.c-sites-dropdown.active > ul {
  visibility: visible;
  opacity: 1;
}

// CTA Buttons

.c-cta-btns {
  a {
    font-size: 18.667px;

    &:last-child {
      margin-left: 16px;
    }
  }
}

.c-header--items {
  display: none;

  @include bp-large {
    display: block;
    font-size: 0px; // Removes whitespace
  }
}

.c-header-menu-wrapper {
  display: flex;
  margin-left: auto;
}
