/* ==========================================================================
   Tools
     > Bgs
   ========================================================================== */

// Images

@mixin t-bg--img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
