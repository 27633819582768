/* ==========================================================================
   Tools
     > Headings
   ========================================================================== */

// Base

@mixin t-heading--base {
  font-family: var(--font--headings);
  font-weight: 500;
  color: var(--color--text);
}

// h1

@mixin t-heading--h1 {
  font-size: var(--font__size--h1);
  line-height: 1.1;
  letter-spacing: -1.28px;

  // .c-article__body & {
  //   font-size: unquote('clamp(2rem, 1.7143rem + 1.4286vw, 4rem)');
  // }
}

@mixin t-heading--h2 {
  font-size: var(--font__size--h2);
  line-height: 1.2;
  margin-bottom: calc(32px - 8px);

  @include bp-medium {
    letter-spacing: -1.134px;
  }
}

// h3

@mixin t-heading--h3 {
  font-size: var(--font__size--h3);
  line-height: 1.25;
  letter-spacing: -0.224px;
}

// h4

@mixin t-heading--h4 {
  // font-size: 22px;
  font-size: var(--font__size--h4);
  line-height: 1.333;
  font-weight: 500;
  letter-spacing: 0;
}

// h5

@mixin t-heading--h5 {
  // font-size: 20px;
  // line-height: 28px;
  font-size: var(--font__size--h5);
  line-height: 1.333;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 16px;
}

// h6

@mixin t-heading--h6 {
  // font-size: 16px;
  font-size: var(--font__size--h6);
  line-height: 1.333;
  font-weight: 600;
  margin-bottom: 16px;
}
