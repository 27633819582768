/* ==========================================================================
   Utilities
     > Fonts
   ========================================================================== */

@include bp-xxlarge {
  .u-fs--22--xxl {
    font-size: 22px;
  }
}
