/* ==========================================================================
Components
  > Search
    > Predictive
========================================================================== */

.results {
  display: none;
  @include shadow(4);
  width: 100%;
  z-index: 999;
  background: #fff;
  border-radius: 24px;
  list-style-type: none;
  margin: 16px auto 0;
  padding: 16px;
  position: absolute;
  top: 36px;

  li {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border-radius: 24px;
    color: #222;
    display: block;

    &:hover,
    &.is-active {
      text-decoration: none;
      color: #fff !important;
      background: var(--color--primary);
    }

    &.suggested {
      padding-left: 32px;
      font-weight: 700;
      color: #111;

      &:before {
        content: '';
        background: var(--color--primary);
        width: 8px;
        height: 8px;
        position: absolute;
        left: 12px;
        top: 16px;
        border-radius: 50%;
      }

      &:hover {
        color: #fff;

        &:before {
          background: #fff;
        }
      }

      &.is-active:before {
        background: #fff;
      }
    }
  }

  &.is-visible {
    display: block;
  }
}
