/* ==========================================================================
   Utilities
     > Text Protection
   ========================================================================== */

// Full

.u-text-protection--full:before {
  opacity: 0.8;
}

// None

.u-text-protection--none:before {
  opacity: 0;
}

// Default

[class*="u-text-protection"] {
   color: #fff;

  h1, h2, h3, p {
    color: #fff;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0,0,0,0.64);
  }
}
