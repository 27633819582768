///* ========================================================================
//   #CLEARFIX
//   ======================================================================== */

// Mixin to drop micro clearfix into a selector.
//
// .usage {
//   @include clearfix;
// }

@mixin clearfix() {
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
