///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

@mixin bp-xsmall {
  @media (min-width: $bp-xsmall) {
    @content;
  }
}

@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

@mixin bp-small-medium {
  @media (min-width: $bp-small-medium) {
    @content;
  }
}

@mixin bp-small-medium-extended {
  @media (min-width: $bp-small-medium-extended) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

@mixin bp-medium-large {
  @media (min-width: $bp-medium-large) {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

@mixin bp-xlarge {
  @media (min-width: $bp-xlarge) {
    @content;
  }
}

@mixin bp-xxlarge {
  @media (min-width: $bp-xxlarge) {
    @content;
  }
}

@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}

@mixin bp-largest {
  @media (min-width: $bp-largest) {
    @content;
  }
}

@mixin bp-largest-really {
  @media (min-width: $bp-largest-really) {
    @content;
  }
}

// Max Widths

@mixin bp-max-xlarge {
  @media (max-width: $bp-max-xlarge) {
    @content;
  }
}

@mixin bp-max-large {
  @media (max-width: $bp-max-large) {
    @content;
  }
}

@mixin bp-max-medium-large {
  @media (max-width: $bp-max-medium-large) {
    @content;
  }
}

@mixin bp-max-medium {
  @media (max-width: $bp-max-medium) {
    @content;
  }
}

@mixin bp-max-small-medium {
  @media (max-width: $bp-max-small-medium) {
    @content;
  }
}

@mixin bp-max-small {
  @media (max-width: $bp-max-small) {
    @content;
  }
}

// Max s or l

@mixin bp-max-small--or-bp-large {
  @media (max-width: $bp-max-small), (min-width: $bp-large) {
    @content;
  }
}
