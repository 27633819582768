/* ==========================================================================
Settings
  > Global
========================================================================== */

@import '../settings/_s-breakpoints.scss';
@import '../tools/_t-breakpoints.scss';

:root {
  --gutter: 28px;
  --gutter--alt: 36px;
  --tap-target__size: 48px;
  --utility__height: var(--tap-target__size);
  --header__height: 64px;
  --alert__height--desktop: 80px;
  --main-search__input__height: 48px;
  --main-search__button__width: var(--tap-target__size);
  --global__transition: all 200ms ease-in-out;
  --global__radius: 40px;
  --c-form__radius: 0px;
  --wrapper__padding: var(--gutter);
  --wrapper__padding--minor: 24px;
  --wrapper__padding--major: 64px 0 56px;
  --spacer: 48px;
  --spacer--alt: calc(40px - 16px);
  --thumb__width: 177px;
  --thumb__height: 177px;
  --btn__padding: 12px 32px;
  --alert__height--desktop: 48px;
  --utility-menu__height: 48px;
  --sidebar-widget-spacing: 32px;
}

@include bp-small {
  :root {
    --wrapper__padding: 40px;
  }
}

@include bp-small-medium-extended {
  :root {
    --spacer: 64px;
  }
}

@include bp-medium {
  :root {
    --wrapper__padding: 64px;
    --wrapper__padding--minor: 32px;
    --spacer: 80px;
    --spacer--alt: calc(64px - 16px);
  }
}

@include bp-medium-large {
  :root {
    --wrapper__padding--minor: 48px;
    --spacer: 96px;
    --spacer--alt: calc(112px - 16px);
    --sidebar-widget-spacing: 40px;
  }
}

@include bp-large {
  :root {
    --header__height: 80px;
    --utility-menu__height: 56px;
  }
}

@include bp-xlarge {
  :root {
    --wrapper__padding: 80px;
  }
}
