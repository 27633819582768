/* ==========================================================================
Utilities
  > Align
========================================================================== */

.u-ta--c {
  text-align: center;
}

.u-ta--r {
  text-align: right;
}
