/* ==========================================================================
   Tools
     > Navigation
   ========================================================================== */

// Border

@mixin t-nav-item__border {
  content: '';
  position: absolute;
  bottom: 32px;
  left: 24px;
  right: 24px;
  height: 2px;
  background: var(--color--primary);
}

// Drop Menus

@mixin t-drop-menu--ui {
  @include shadow(3);
  overflow: hidden;
  top: var(--header__height);
  left: 16px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  margin: 0;
  background: #fff;
  padding: 8px 0 12px 0;
  border-top: 0;
  z-index: 20;
  width: 275px;
  list-style-type: none;
  border-radius: 0 0 16px 16px;

  li {
    a {
      display: flex;
      align-items: center;
      gap: 0 8px;
      color: #222;
      text-decoration: none;
      font-size: 18px;
      padding: 8px 16px;

      &:hover {
        color: var(--color--primary);
        background: var(--color--primary--lightest);
        text-decoration: none;
      }

      .fa-lock {
        margin-left: auto;
      }
    }
  }
}
