/* ==========================================================================
   Tools
     > Btns
   ========================================================================== */

// Base

@mixin t-btn {
  display: inline-block;
  text-align: center;
  margin: 0;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: var(--global__radius);
  text-decoration: none;
  color: #fff;
  background-color: var(--color--primary);
  padding: var(--btn__padding);
  line-height: 28px;
  width: 100%;

  &:hover {
    background: var(--color--primary--dark);
    color: #fff;
    text-decoration: none !important;
  }

  &:focus-visible {
    outline: 2px solid var(--color--primary);
    outline-offset: 2px;
  }

  @include bp-small {
    width: auto;
  }
}

// Outline

@mixin t-btn--outline {
  background: transparent;
  border: 2px solid var(--color--primary);
  color: var(--color--primary);

  &:hover {
    background: var(--color--primary);
  }
}

// Outline - Secondary (Orange)

@mixin t-btn--outline--secondary {
  color: var(--color--secondary);
  border-color: var(--color--secondary);

  &:hover {
    color: #fff;
    background: var(--color--secondary);
  }
}

// Outline - Light

@mixin t-btn--outline--light {
  color: #fff;
  border-color: #fff;

  &:hover {
    color: #444 !important;
    background: #fff;
  }

  &:focus {
    outline-color: #fff;
  }
}

// Header - Base

@mixin t-btn--header--base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header__height);
  line-height: var(--header__height);
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

// Header - icon

@mixin t-btn--header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 40px;
  height: 40px;
  text-align: center;
}

@mixin t-btn--remove-default {
  background: none;
  border: none;
  text-align: left;

  &:focus-visible {
    outline: 2px solid var(--color--primary);
  }
}
