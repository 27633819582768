/* ==========================================================================
   Tools
     > Form -- UI
   ========================================================================== */

@mixin t-form--ui {
  background: var(--color--primary--lightest);
  border: none;
  padding: 0 24px;
  width: 100%;
  height: 64px;
  line-height: 64px;
  font-family: var(--font--body);
  font-weight: 400;
  color: var(--color--text);
  border-radius: var(--global__radius);

  &:disabled {
    pointer-events: none;
  }

  &:not(.c-btn):disabled {
    background-color: transparent;
    border: 1px solid var(--color--border);
  }

  @media (max-width: 374px) {
    font-size: 18px;
  }

  &:focus-visible {
    outline: 2px solid var(--color--primary);
    outline-offset: 2px;
  }
}

/*
  Floated Labels:
    https://github.com/tonystar/float-label-css
    https://github.com/tonystar/float-label-css/blob/master/scss/_mixins.scss
*/

@mixin float-label-container {
  display: block;
  position: relative;
  width: 100%;
}

@mixin float-label {
  label {
    pointer-events: none;
    position: absolute;
    top: -8px;
    left: 24px;
    font-size: 13px;
    cursor: text;
    transition: all 0.2s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 1.5;
    background-color: #fff;
    padding: 0 4px;
    border-radius: 8px;
    @content;
  }
}

@mixin float-label--s {
  label {
    top: 0;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 0;
    transition: all 0.2s;
  }

  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
    @content;
    font-size: 18px;
    color: var(--color--text);
    top: 16px;
    left: 24px;
    background-color: transparent;
  }
}

@mixin float-label-scaled--s {
  &:placeholder-shown:not(:focus) + * {
    top: 10px;
  }
}
