/* ==========================================================================
Objects
  > Main
========================================================================== */

main.o-wrapper > .o-container,
.c-footer .o-container {
  padding-inline: var(--wrapper__padding);

  @include bp-xxlarge {
    padding-inline: var(--gutter);
  }
}

main.o-wrapper > .o-container {
  padding-block: 40px;

  @include bp-medium-large {
    padding-block: 64px;
  }

  @include bp-xxlarge {
    padding-block: 80px;
  }
}
