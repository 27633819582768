.c-dialog {
  padding: 16px 48px 16px 16px;
  max-width: calc(100vw - var(--gutter) * 2);
  max-height: 90vh;
  border: 0;
  border-radius: 4px;

  @include bp-medium {
    padding: 24px 72px 24px 24px;
    max-width: 75vw;
  }

  @include bp-xlarge {
    max-width: 960px;
  }

  &::backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  &--btn {
    position: absolute;
    top: 0px;
    right: 0px;

    @include bp-large {
      top: 10px;
      right: 10px;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
