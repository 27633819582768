/* ==========================================================================
   Tools
     > Resets
   ========================================================================== */

// Buttons

@mixin t-reset--btn {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  &:enabled {
    cursor: pointer;
  }
}

// Col - 50

@mixin t-reset--col--50 {
  width: 50% !important;
  margin-bottom: var(--gutter) !important;
}

// Col - 100

@mixin t-reset--col--100 {
  float: none;
  width: 100% !important;
  margin-bottom: var(--gutter) !important;
}
