/* ==========================================================================
Components
  > Navigation
========================================================================== */

// Main Nav

.c-main-nav {
  display: none;

  @include bp-xlarge {
    display: flex;
    justify-content: center;
    margin: 0 var(--gutter) 0 auto;
    width: 100%;

    .c-main-nav__list {
      font-size: 0px;
    }

    > ul {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;

      > li {
        display: inline-flex;
        padding: 0;
        flex-shrink: 0;

        > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          font-family: sans-serif;
          font-size: 18px;
          color: #fff;
          position: relative;
          padding: 0 24px;
          height: var(--header__height);
          border-radius: var(--global__radius);
          z-index: 1;

          .fa-lock {
            position: relative;
            top: -2px;
            margin-right: 8px;
          }

          // Background - workaround as items need to be full height of header to not lose pointer when moving from item to wither dropdown or mega menu.

          &:after {
            display: flex;
            background-color: #fff;
            height: 48px;
            border-radius: 48px;
            position: absolute;
            bottom: 15px;
            width: calc(100% - 16px);
            left: 8px;
            z-index: -1;
          }

          &:hover,
          &:focus-visible {
            color: var(--color--text);

            &:after {
              content: '';
            }
          }

          &:focus-visible {
            outline: none;
          }
        }

        &.active a {
          color: var(--color--text);

          &:after {
            content: '';
          }
        }

        &.has--dropdown,
        &.has--mega {
          > a:after {
            width: 100%;
          }
        }

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          background: none;
          border: 0;
          font-size: 14px;
          padding: 0;
          pointer-events: none;
          margin: 0 16px 0 -28px;

          .far {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            color: #fff;
            width: 32px;
            height: 32px;
            margin-left: 0px;
          }

          &:focus-visible {
            outline: none;

            .far {
              outline: 2px solid #fff;
              border-radius: 100%;
              outline-offset: -6px;
            }
          }

          &[aria-expanded='true'] {
            .far {
              transform: rotate(180deg);
            }
          }
        }

        &.has--dropdown,
        &.has--mega {
          &.active {
            a + button .far {
              color: var(--color--text);
            }
          }

          a {
            &:hover,
            &:focus-visible {
              + button .far {
                color: var(--color--text);
              }
            }
          }
        }
      }
    }

    .fa-lock {
      font-size: 14px;
    }
  }
}

.c-main-nav > ul > li {
  &.active {
    .far.fa-chevron-down {
      transform: rotate(180deg);
    }
  }
}

// Mega Menu

.c-mega-menu {
  display: none;
  position: absolute;
  width: 100vw;
  background: #fff;
  top: calc(var(--header__height) + 56px);
  left: 0;
  bottom: auto;
  z-index: 9;
  border-top: 1px solid var(--color--grey--light);
  border-bottom: 1px solid var(--color--grey--light);
  font-size: initial;

  .o-container {
    @include bp-xlarge {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0 64px;
    }

    @include bp-xxlarge {
      padding: 0 var(--gutter);
    }
  }

  // Header

  .c-mega__header {
    display: flex;
    border-bottom: 1px solid var(--color--grey--light);
    padding: 56px 0 40px 0;

    .c-btn {
      align-self: flex-start;
      position: relative;
      font-weight: 400;
      font-size: 18px;
      background: transparent;
      color: var(--color--text);
      font-family: var(--font--headings);
      order: 2;
      margin: 0 -8px 0 auto;
      padding: 0 8px;
      width: auto;

      &:hover {
        color: var(--color--primary);
      }

      &:focus-visible {
        background: transparent;
        color: var(--color--primary);
      }

      .fal fa-times {
        position: relative;
        top: 3px;
        margin: 0 0 0 8px;
        font-size: 20px;
      }
    }

    // Section

    section {
      width: 66.6666666667%;

      h2 {
        font-size: 32px;
        font-weight: 600;
        letter-spacing: -0.88px;
        color: var(--color--primary);
        margin: 0 0 12px 0;
      }

      p {
        font-size: 24px;
        line-height: 34px;
        margin: 0;
      }
    }
  }

  // Hold

  .c-menu-footer__hold {
    display: flex;
    overflow: hidden;
  }
}

// If Alert

.has--alert {
  .c-mega-menu {
    top: calc(
      var(--alert__height--desktop) + var(--utility-menu__height) +
        var(--header__height)
    );
  }
}

// Menu Levels

.c-menu__levels {
  display: flex;
  flex-shrink: 0;
  width: 100%;

  .c-menu__level {
    position: relative;
    height: 100%;

    &.c-menu__level--0 {
      width: 33.333%;

      &:after {
        content: '';
        background: var(--color--grey--light);
        width: 1px;
        height: 200%;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.c-menu__level--1 {
      width: 66.666%;

      ul {
        column-count: 3;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: var(--gutter) 0;
      height: 100%;

      a,
      button {
        display: flex;
        gap: 8px;
        align-items: center;
        line-height: 32px;
        margin: 0;
        padding: 8px 24px;
        font-size: 18px;
        width: 100%;
        text-align: left;
        background: transparent;
        border: none;
        cursor: pointer;
        color: var(--color--text);

        .fa-lock,
        .fa-chevron-right {
          width: 24px;
          text-align: center;
          margin-left: auto;
          flex-shrink: 0;
        }

        .fa-chevron-right {
          font-size: 14px;
        }

        &:hover,
        &:focus-visible,
        &.active {
          color: var(--color--primary);
          background-color: var(--color--primary--lightest);
          outline: none;
        }
      }
    }
  }
}

.c-menu__item--direct {
  a {
    .c-menu__levels .c-menu__level ul & {
      @include t-heading--h3;
      column-span: all;
      white-space: normal;
      text-overflow: unset;
      line-height: var(--tap-target__size);
      color: var(--color--primary);

      .fa-lock {
        color: var(--color--text);
      }
    }
  }
}

.c-main-nav > ul > li.active .c-mega-menu {
  display: block;
}

// Drop Menu

.drop-menu {
  position: relative;

  > ul {
    @include t-drop-menu--ui;
  }

  &.active > ul {
    visibility: visible;
    opacity: 1;
  }
}

.c-menu__level--1 .ada-menu-mega {
  display: none;
}
