/* ==========================================================================
   Tools
     > Banner UI
   ========================================================================== */

@mixin t-banner--ui {
  > * {
    margin: 32px 0 0;

    @include bp-medium {
      margin-top: 40px;
    }
  }

  h1 {
    margin-top: 0;

    @include bp-medium-large {
      max-width: 1024px;
    }

    + p {
      margin-top: 24px;

      @include bp-medium {
        margin-top: 32px;
      }
    }
  }

  p {
    width: 86%;

    @include bp-xlarge {
      width: 100%;
      max-width: 720px;
    }
  }
}
