/* ==========================================================================
Utilities
  > Position
========================================================================== */

// Vertical - top

.u-pos--v-t {
  vertical-align: top;
}

// Relative

.u-pos--rel {
  position: relative;
}

@include bp-large {
  .u-pos--sticky--l {
    position: sticky;
    top: 40px;
  }
}
