/* ==========================================================================
Utilities
  > Column Count
========================================================================== */

[class^='u-col-count'] {
  column-gap: var(--gutter);

  * {
    break-inside: avoid;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.u-col-count {
  column-gap: var(--gutter);

  .col {
    float: none;
    break-inside: avoid;
  }
}

// 2

.u-col-count--02 {
  column-count: 2;
}

@include bp-small-medium {
  .u-col-count--02--sm {
    column-count: 2;
  }

  .u-col-count--03--sm {
    column-count: 3;
  }
}

@include bp-medium {
  .u-col-count--02--m {
    column-count: 2;
  }
  .u-col-count--03--m {
    column-count: 3;
  }
}

@include bp-large {
  .u-col-count--02--l {
    column-count: 2;
  }
}
