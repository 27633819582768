/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: var(--color--primary--lightest);
  position: relative;

  .c-logo {
    display: inline-flex;
    height: 32px;

    img {
      width: auto;
      height: 100%;
    }
  }

  @include bp-medium-large {
    .c-footer--primary {
      display: flex;
      flex-wrap: wrap;

      .col {
        margin-left: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  h3 {
    font-size: 20px;
    position: relative;
    color: var(--color--text);
    text-decoration: none;
    margin: 0;

    @include bp-medium-large {
      font-size: 28px;
      margin-bottom: 24px;
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: var(--color--primary);
      width: 100%;
      text-align: right;
      height: var(--tap-target__size);

      &[aria-expanded='true'] .far {
        transform: rotate(180deg);
      }

      @include bp-medium-large {
        display: none;
      }
    }

    a {
      font-weight: 500;
      color: #444;
      text-decoration: none;

      @include bp-medium-large {
        font-weight: 400;
        color: var(--color--primary);
      }
    }

    &.active span::before {
      content: '\ed8c';
    }
  }

  .footer-menu ul {
    display: none;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
    margin-bottom: 0;

    @include bp-medium-large {
      display: flex;
    }

    &.active {
      display: flex;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    font-size: 18px;

    li {
      list-style-type: none;

      a {
        color: #444;
        font-family: var(--font--body);
        text-decoration: none;

        &:hover {
          color: var(--color--primary);
          text-decoration: underline;
        }
      }
    }

    &.c-footer-social {
      display: flex;
      flex-wrap: wrap;
      margin: 16px 0 0 -8px;
      padding: 0;
      gap: 8px;

      li {
        margin: 0;

        a {
          display: flex;
          align-items: center;
          font-size: 18px;
          height: 40px;
          width: 40px;
          justify-content: center;
          color: var(--color--text);
          border-radius: 16px;

          &:hover {
            color: var(--color--primary);
            background-color: var(--color--primary--lightest);
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

// Secondary

.c-footer--secondary {
  font-size: 16px;
  margin: 0;

  .row {
    margin-bottom: 0;

    .col {
      margin-bottom: 0;

      @include bp-max-medium-large {
        &:last-child {
          margin-top: 16px;
        }
      }
    }
  }

  @include bp-medium-large {
    display: flex;
    flex-direction: column;

    .col {
      flex-shrink: 0;
    }
  }

  ul,
  p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  ul {
    li {
      margin-top: 0;
    }
  }
}

// Tertiary

.c-footer--tertiary {
  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .col {
      margin-bottom: 0;

      &:last-child {
        order: -1;
      }
    }

    @include bp-medium-large {
      flex-direction: row;

      .col {
        &:last-child {
          order: 1;
        }
      }
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 0;
  }
}
