/* ==========================================================================
Components
  > CTA Banner
========================================================================== */

.c-cta-banner {
  text-align: center;
  padding: 32px 0;
  width: 100%;
}
