/* ==========================================================================
Tools
  > Links
========================================================================== */

// Hover

@mixin t-link__hover {
  color: var(--color--primary--dark);
  text-decoration: underline;
}

@mixin t-link__hover--bg {
  background-color: var(--color--primary--lightest);
  padding-inline: 8px;
  border-radius: 24px;
}
