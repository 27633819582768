/* ==========================================================================
Settings
  > Colour
========================================================================== */

:root {
  // Text

  --color--text: #222;

  // Primary

  --color--primary: hsl(155, 46%, 30%);
  --color--primary--dark: hsl(154, 44%, 14%);
  --color--primary--transparency: hsla(155, 46%, 30%, 0.64);
  --color--primary--32: hsla(155, 46%, 30%, 0.32);
  --color--primary--light: rgba(42, 113, 83, 0.32);
  --color--primary--lightest: rgba(42, 113, 83, 0.1);

  // Secondary

  --color--secondary: #1bbfce;

  // Tertiary

  --color--tertiary: #e98a68;
  --color--tertiary--grey: #ebebeb;

  // Sectors

  --color--sector--early-childhood: #70399a;
  --color--sector--primary: #eb1400;
  --color--sector--junior-cycle: #13863f;
  --color--sector--senior-cycle: #d34203;

  // Groups (Learning Outcome)

  --color--group1: rgb(70, 121, 189);
  --color--group2: rgb(83, 129, 53);
  --color--group3: rgb(255, 164, 0);

  // Greys

  --color--grey: #ccc;
  --color--grey--medium: #c8c8c8;
  --color--grey--dark: #757575;
  --color--grey--light: #ddd;
  --color--grey--lighter: #f4f5f9;

  // Whites

  --color--white--32: hsla(0, 0%, 100%, 0.32);
  --color--white--0-16: hsla(0, 0%, 100%, 0.16);

  // Blacks

  --color--off-black: #222;

  // Link

  --color--link: var(--color--primary);

  --color--border: rgba(42, 113, 83, 0.5);

  // Warning

  --color--red: #b90000;

  // Progression steps

  --color--step1: #6c2769;
  --color--step1--bg: #e0d6df;

  --color--step2: #6e0e10;
  --color--step2--bg: #decccc;

  --color--step3: #d74926;
  --color--step3--bg: #f9d3cc;

  --color--step4: #8d712c;
  --color--step4--bg: #e5e2cc;

  --color--step5: #54813b;
  --color--step5--bg: #d9ebcc;

  --color--step6: #008181;
  --color--step6--bg: #d3ece4;

  --color--step7: #308bc0;
  --color--step7--bg: #d8edfb;

  --color--step8: #44546a;
  --color--step8--bg: #d5dbe4;

  --color--step9: #8333b3;
  --color--step9--bg: rgba(131, 51, 179, 0.14);

  --color--step10: #cf388b;
  --color--step10--bg: rgba(207, 56, 139, 0.14);

  --color--step11: #c73738;
  --color--step11--bg: rgba(199, 55, 56, 0.14);

  // Others

  --color--blue: #5b9bd5;
  --color--blue--secondary: #d6e1f1;
  --color--green--secondary: #d9e6d4;
}
