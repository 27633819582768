.c-color-key {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 32px;
  margin: 0 0 40px 0;

  li {
    position: relative;
    display: flex;
    align-items: baseline;
    column-gap: 8px;

    &::before {
      content: '';
      width: 15px;
      height: 15px;
    }
  }
  &--group1 {
    &::before {
      background-color: var(--color--group1);
    }
  }
  &--group2 {
    &::before {
      background-color: var(--color--group2);
    }
  }
  &--group3 {
    &::before {
      background-color: var(--color--group3);
    }
  }
}
