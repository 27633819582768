/* ==========================================================================
Components
  > Media
========================================================================== */

// 50 / 50

.c-media--50-50 {
  .col {
    position: relative;
  }

  h2,
  figure,
  p {
    margin-bottom: 0;
  }

  p,
  .c-list {
    margin-top: 24px;
  }

  .c-btns {
    margin-top: 32px;
  }

  a:not(.c-btn) {
    text-decoration: underline;
  }

  // Facilitate flex order @sm

  @include bp-medium {
    display: flex;
  }

  .col__text {
    margin-bottom: 0;

    .o-wrapper--media--no-padding & {
      padding-bottom: 24px;

      @include bp-small-medium {
        padding-left: 40px;
        padding-top: 24px;
      }
    }

    .o-wrapper--media--no-padding:not(.u-bg-color--stretch) & {
      padding-left: 40px;
      padding-right: 40px;

      @include bp-small-medium {
        padding-top: 24px;
      }
    }
  }

  .u-order--2 + .col__text {
    @include bp-medium-large {
      padding-right: 32px;
    }

    @include bp-large {
      padding-right: 64px;
    }

    .o-wrapper--media--no-padding.u-bg-color--stretch & {
      padding-left: 0;
    }
  }
}

// 50 / 50

@include bp-large {
  .c-article--main {
    &.l5 {
      .c-media--50-50 {
        .col {
          @include t-reset--col--100;
        }
      }
    }
  }
}
