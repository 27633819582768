/* ==========================================================================
Settings
  > Typography
    https://utopia.fyi/type/calculator
========================================================================== */

:root {
  --font__size--base: 18px;
  --line__height--base: 30px;
  --font--headings: 'Inter', sans-serif;
  --font--body: 'Inter', sans-serif;
  --font--awesome: 'Font Awesome 6 Pro';

  --step--2: clamp(0.72rem, 1rem + -0.35vw, 0.93rem);
  --step--1: clamp(0.9rem, 1.0633rem + -0.2042vw, 1.0225rem);
  --step-0: clamp(1.125rem, 1.125rem + 0vw, 1.125rem);
  --step-1: clamp(1.2375rem, 1.1813rem + 0.2813vw, 1.4063rem);
  --step-2: clamp(1.3613rem, 1.229rem + 0.6615vw, 1.7581rem);
  --step-3: clamp(1.4975rem, 1.2642rem + 1.1667vw, 2.1975rem);
  --step-4: clamp(1.6469rem, 1.2802rem + 1.8333vw, 2.7469rem);
  --step-5: clamp(1.8119rem, 1.2715rem + 2.7021vw, 3.4331rem);
  --step-6: clamp(1.8119rem, 0.8375rem + 4.8719vw, 4.735rem);

  --font__size--h1--hero: var(--step-6);
  --font__size--h1: var(--step-5);
  --font__size--h2: var(--step-3);
  --font__size--h3: var(--step-2);
  --font__size--h4: var(--step-1);
  --font__size--h5: 16px;
  --font__size--h6: 14px;

  // --font__size--h4: clamp(1.125rem, 1.125rem + 0vw, 1.25rem);
  // --font__size--h3: clamp(1.2656rem, 1.1877rem + 0.3896vw, 1.4994rem);
  // --font__size--h2: clamp(1.4238rem, 1.2321rem + 0.9583vw, 1.9988rem);
  // --font__size--h1: clamp(2.0275rem, 1.125rem + 4.5125vw, 4.735rem);
}
