/* ==========================================================================
Tools
  > Figures
========================================================================== */

// Event

@mixin t-event {
  width: 100%;
  display: block;
  position: relative;

  @include bp-small {
    padding-left: 94px;
  }
}

// Calendar

@mixin t-figure--calendar {

  .month-year {
    display: none;

    @include bp-small {
      display: block;
    }
  }

  .calendar-detail-mo,
  .calendar-detail-yr {
    display: inline-block;
  }

  
}

@mixin t-figure--calendar__detail {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  top: 0;

  .month-year,
  .calendar-detail {
    height: 24px;
    line-height: 24px;
    width: 70px;
    background: var(--color--primary);
    color: #fff;
    font-size: 13px;
    width: 100%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;

    &.c-date--F {
      font-size: 12px;
    }
  }

  .day {
    display: block;
    width: 100%;
    height: 46px;
    line-height: 46px;
    text-align: center;
    font-weight: 500;
    font-size: 25px;
    border: 1px solid #ccc;
    border-top: 0;
  }

  .c-meta__date {
    display: none;
  }
}