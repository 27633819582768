/* ==========================================================================
Components
  > Alerts
========================================================================== */

.c-alert {
  display: none;
  position: relative;
  padding: 8px 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid var(--color--border);

  .o-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: calc(var(--tap-target__size) + 8px);

    @include bp-large {
      padding-right: calc(var(--alert__height--desktop) + 8px);
    }
  }

  // Desktop

  &.c-alert--desktop {
    background: #fff !important;
    font-size: 20px;
    height: var(--alert__height--desktop);

    @include bp-large {
      padding: 0;
      overflow: hidden;
      text-align: center;

      .c-alert__title,
      .c-alert__subtext,
      a {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .c-alert__title,
      .c-alert__subtext {
        max-width: 40%;
      }

      .c-alert__subtext {
        margin-right: 24px;
      }
    }
  }

  // Mobile

  &.c-alert--mobile {
    font-size: 18px;
    text-align: center;
    box-shadow: none;
    background: #fff !important; // temp
  }

  // Close

  .c-btn--close__alert {
    width: var(--tap-target__size);
    height: var(--tap-target__size);

    @include bp-large {
      width: var(--alert__height--desktop);
      height: var(--alert__height--desktop);
    }
  }

  // Icons

  .fal {
    font-size: 24px;

    .fa-times {
      margin-right: 0;
    }
  }

  // Media

  .c-alert__media {
    flex-shrink: 0;
    width: 64px;
    margin-right: 16px;
    text-align: right;

    figure {
      @include fluid-aspect(3 2);
      margin: 0;
    }
  }

  // Title

  .c-alert__title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 24px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  // Link

  a {
    text-decoration: underline;

    @include bp-large {
      white-space: nowrap;
      max-width: 20%;
    }
  }
}

.has--alert {
  .c-alert {
    &:not(.c-alert--desktop) {
      display: flex;

      @include bp-large {
        display: none;
      }
    }

    @include bp-large {
      &.c-alert--desktop {
        display: flex;
      }
    }
  }
}
