/* ==========================================================================
Elements
  > Body
    Required for elements that use .u-bg-color--stretch
    Prevents horizontal scrollbars on Safari.
========================================================================== */

body {
  overflow-x: hidden; // for no 'clip' support = no sticky
  overflow-x: clip; // = sticky ok

  /*
  &:after {
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    content: '';
  }

  &.nav--focus:after {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.16);
    position: fixed;
    top: 0;
    opacity: 1;
  }
  */
}
