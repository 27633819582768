/* ==========================================================================
Objects
  > Wrapper
========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  width: 100%;
}

// Minor

.o-wrapper--minor {
  padding: var(--wrapper__padding--minor) 0;
}

// Widgets in sidebar

aside .o-wrapper {
  // padding: var(--wrapper__padding--minor) 0;
  margin-top: var(--sidebar-widget-spacing) !important;
  margin-bottom: 0 !important;
}

@include bp-medium {
  .o-wrapper--minor--m {
    padding: var(--wrapper__padding--minor) 0;
  }
}

// Major (@max sm)

@include bp-max-small-medium {
  .o-wrapper--major--max--sm {
    padding: var(--wrapper__padding--major);
  }
}

// Quick link

.o-wrapper--quick-link {
  padding: var(--wrapper__padding--quick-link);
}

// Hero

.o-wrapper--hero {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Tab

.o-wrapper--tab {
  display: flex;
  position: relative;
  flex-direction: column;

  @include bp-medium-large {
    flex-direction: row;
  }
}

// Sticky

.o-wrapper--sticky {
  position: sticky;
  top: 0;
  transition: all 0.4s;
  z-index: 9;

  .o-container {
    display: flex;
    align-items: center;
  }
}

.o-wrapper--has-bg {
  padding-top: var(--wrapper__padding);
  padding-bottom: var(--wrapper__padding);

  &:not(.u-bg-color--stretch) {
    padding-left: var(--wrapper__padding);
    padding-right: var(--wrapper__padding);
  }
}
