/* ==========================================================================
Components
  > Hamburger
========================================================================== */

.c-hamburger {
  .c-hamburger-inner {
    &,
    &::before,
    &::after {
      transition-property: none;
    }
  }

  &.is-active {
    .c-hamburger-inner {
      transform: rotate(45deg);

      &::before {
        top: 0;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
      }
    }
  }

  .c-btn__icon {
    // pointer-events: none; /* Smooth */
    margin-left: 3px;

    @include bp-medium-large {
      margin-left: 0;
    }
  }
}

/* ==========================================================================
Components
  > Hamburger - Base
========================================================================== */

.c-hamburger {
  @media (max-width: 639px) {
    position: absolute;
    top: 0;
  }

  right: var(--gutter);
  padding: 0;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 0 0 16px;
  overflow: visible;

  &.is-active {
    .c-hamburger-inner,
    .c-hamburger-inner::before,
    .c-hamburger-inner::after {
      background-color: #fff;
    }
  }

  &:focus-visible {
    outline: none;

    .c-btn__icon {
      outline: 2px solid #fff;
      border-radius: 8px;
    }
  }

  @include bp-xlarge {
    display: none;
  }
}

.c-hamburger-box {
  width: 24px;
  height: 16px;
  display: inline-block;
  position: relative;
}

.c-hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;

  &,
  &::before,
  &::after {
    width: 24px;
    height: 2px;
    background-color: #fff;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: -7px;
  }

  &::after {
    bottom: -7px;
  }
}
