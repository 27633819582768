/* ==========================================================================
   #TABLE
   ========================================================================== */

.c-table--responsive,
.tableContainer {
  @include t-table--responsive;
}
// Base / Standard

.c-table,
table {
  > thead {
    color: #fff;
    background-color: var(--color--primary);
  }

  &:not(.c-table--bordered) {
    > thead {
      a {
        color: #fff;

        &:hover {
          color: #fff !important;
        }

        &:focus-visible {
          outline-color: #fff;
        }
      }
    }
  }

  th {
    text-align: left;
    padding: 8px 16px;
    font-weight: 400;
    border: 1px solid var(--color--border);

    @include bp-medium {
      padding: 16px 24px;
    }
  }

  thead th {
    font-weight: 700;
  }

  td,
  th[scope='row'] {
    padding: 8px 16px;
    border: 1px solid var(--color--border);
    vertical-align: top;

    @include bp-medium {
      padding: 16px 24px;
    }
  }

  td {
    h4 {
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  // Compact

  &.c-table--compact {
    @include t-table--compact;
  }

  // Striped (even row bg, no lines)

  &.c-table--striped {
    @include t-table--striped;
  }

  // Bordered (no thead bg)

  &.c-table--bordered {
    @include t-table--bordered;
  }
}

// 4 columns

.c-table--4cols {
  tr {
    th,
    td {
      width: 25%;
      min-width: 240px;
    }
  }
}

// Outcomes table

.c-table--outcomes {
  th,
  td {
    padding: 16px 8px;

    @include bp-max-medium {
      display: block;
      width: 100%;
    }
  }
  thead {
    th {
      text-align: center;
      font-weight: 400;
      line-height: 1.2;

      &:not(:last-of-type) {
        border-right: 1px solid #cacaca;
      }

      @include bp-max-medium {
        &:first-of-type {
          display: none;
        }
      }
    }
  }
  tbody {
    th,
    td {
      text-align: left;
      vertical-align: top;
    }
    th {
      font-size: 16px;
      &[scope='row'] {
        padding: 16px 8px;
        font-weight: 600;

        @include bp-max-medium {
          border-top: none;
        }

        p {
          font-size: 14px;
          font-weight: 400;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    td {
      font-size: 14px;
      padding: 16px;
      @include bp-max-medium {
        border-top: none;
      }
    }
  }
  ol {
    list-style-type: none;
    margin: 0;
    &.u-col-count--02--m {
      @include bp-medium {
        column-gap: 32px;
        column-rule: solid 1px var(--color--border);
        li {
          display: inline-block;
        }
      }
    }
    li p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.c-table--25-75 {
  tr {
    td,
    th {
      &:first-child {
        @include bp-medium {
          width: 25%;
        }
      }
    }
  }
}

// Captcha

.CaptchaTable {
  th,
  td {
    padding: 0;
    border: none;
    background: transparent;
  }
  img {
    margin: 0 auto 16px;
  }
}

// Table - Alt

.c-tbl--alt {
  th,
  td {
    border: 4px solid var(--color--grey--light);
  }

  th:first-child,
  td:first-child {
    border-left: none;
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }
}

.c-tbl--alt td {
  background: #fff;
}

// Table - Stripped

.c-tbl--stripped {
  th,
  td {
    display: block;
    background: none;
    padding: 0;
    border: none;
  }

  tr {
    display: block;
    margin-bottom: 1em;
  }
}

// Forum Content

.ForumContent {
  padding: 0;
  margin: -2px 0 0 0;
  display: block;
  overflow: hidden;
  border: none !important;
}

tr.Info {
  margin: 0 0 16px 0;
  display: block;
}

.Info td,
.Actions td,
.ForumDescription {
  display: block;
}

.Info td,
.Actions td {
  background: none;
  padding: 0;
  margin: 0;
}

.ForumName,
.GroupName,
.ForumDescription,
.GroupDescription {
  margin-bottom: 0;
}

.Actions {
  display: block;
  margin-bottom: 16px;
}

.ForumBreadCrumbs {
  display: block;
  margin-top: 16px;
}

.ThreadInfo,
.ForumInfo {
  min-width: 260px;

  h3 {
    margin-bottom: 0;
  }
}

a.ThreadName,
a.ForumName {
  text-decoration: none;
  color: #111;
}

// Hide Management Actions for larger screens. (Revisit to target no-touch only)

@include bp-large {
  .ThreadInfo,
  .ForumInfo {
    .ForumManage {
      opacity: 0;
      transition: var(--global__transition);
    }
  }

  .Thread:hover > .ThreadInfo > .ForumManage,
  .Forum:hover > .ForumInfo > .ForumManage {
    opacity: 1;
  }
}

a.ActionLink,
.PostTime {
  font-size: 15px;
  color: var(--color--grey--light);
}

a.ActionLink:hover {
  color: var(--color--tertiary);
}

.LastPost span:nth-child(2) {
  color: var(--color--grey--light);
}

.c-view-mode {
  float: left;

  @include bp-medium {
    float: right;
    text-align: right;
  }
}

.AvatarImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

// TS00014374 - WCAG 2.1 A F87 - Commenting out as not in codebase

// Replace unreachable Kentico output (table actions)

// .c-tbl--actions-plain tbody {
//   .unigrid-actions button {
//     font-size: 14px !important;

//     i {
//       display: none;
//     }

//     &:hover {
//       background: var(--color--secondary);
//     }

//     &:after {
//       content: '';
//     }

//     &:before {
//       font-family: var(--font--body);
//       font-size: 14px !important;
//     }

//     &.js-edit:before {
//       content: 'Edit';
//     }

//     &.js-delete:before {
//       content: 'Delete';
//     }
//   }
// }

// Vertical

.c-table--vertical {
  width: 100%;
  margin: 0 auto 16px;
  font-size: 16px;

  th {
    text-align: left;
  }
}

// Block

.c-table--block {
  text-align: left;

  th,
  tr,
  td {
    display: block;
    width: 100%;
  }

  tr {
    margin-bottom: 8px;
  }
}

// Block - @max m

@include bp-max-medium {
  .c-table--block--max--m {
    th,
    tr,
    td {
      display: block;
      width: 100%;
    }

    tr {
      margin-bottom: 8px;
    }
  }
}

// Inherited

.tableContainer {
  @include t-table--responsive;
}
