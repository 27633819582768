.c-list {
  &.c-list--resource {
    padding: 32px;
    border-radius: var(--global__radius);
    border: 1px solid var(--color--primary--32);

    .u-bg-color--primary &,
    .u-bg-color--primary--dark & {
      border: 1px solid var(--color--white--32);
    }

    li {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.c-document {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px 16px;
  padding: 8px 0 24px 0;
  color: var(--color--text);

  &:not(:hover) {
    text-decoration: none !important;
  }

  .c-list--resource & {
    padding-block: 4px;
  }

  &--resource {
    position: relative;
    display: grid;
    grid-template-columns: 24px auto 24px;
    grid-template-rows: auto;

    h2 {
      margin-bottom: 0;
      color: #757575;
      font-weight: 400;

      a {
        color: var(--color--primary);
        font-weight: 500;
      }
    }

    p {
      grid-column: 2;
      margin: 4px 0 0;

      + p {
        margin-top: 8px;
      }
    }
  }

  [class^='fa'] {
    text-align: center;
    margin-top: 5px;
    font-size: 20px;
  }

  .fa-lock {
    font-size: 14px;
  }

  @media (hover) {
    a:hover {
      color: var(--color--primary);
    }
  }
}
