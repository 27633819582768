/* ==========================================================================
Utilities
  > Images
========================================================================== */

// Round

.u-image--round {
  border-radius: 100%;
}

.u-image__hold {
  display: flex;
  align-items: center;
  position: relative;
}

.u-image__content {
  @include t-banner--ui;
  position: relative;
  z-index: 2;
  /*
  padding-top: var(--spacer);
  margin: 0 0 var(--spacer) var(--spacer);
  */
}

/* ==========================================================================
  Text Alignment
========================================================================== */

// Centre

[data-component__text-alignment='centre'] {
  text-align: center;

  .u-image__content {
    margin-left: auto;
    margin-right: auto;

    > * {
      margin-left: auto;
      margin-right: auto;
    }

    .c-btn {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.u-image {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;

  > div {
    position: relative;
    height: 100%;
  }

  img {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
  }
}

/* ==========================================================================
  Width
========================================================================== */

// Full

[data-component__width='wide'] {
  .u-image {
    width: 100vw;
    left: 50%;
    margin-left: -50vw;
  }
}
