/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: var(--color--primary);
  text-decoration: none;

  &:hover {
    color: var(--color--primary);
  }
}

// Link List

.c-link-list {
  .col:last-child {
    margin-bottom: 0;
  }

  h4 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 16px;

    @include bp-large {
      margin-bottom: 24px;
    }
  }

  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 0.5rem;

      a {
        color: var(--color--text);
        text-decoration: none;

        &:hover {
          @include t-link__hover;
        }
      }
    }
  }

  @include bp-medium-large {
    .col {
      margin-bottom: 0 !important;
    }
  }
}
