/* ==========================================================================
   #LIST-BARE
   ========================================================================== */

/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */

.o-list-bare {
  list-style: none;
  margin-left: 0;

  a {
    color: var(--color--text);
    text-decoration: none;

    &:hover {
      @include t-link__hover;
    }
  }
}
