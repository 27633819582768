/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */

blockquote {
  font-size: 18px;
  line-height: 1.38889;
  background: var(--color--tertiary--grey);
  padding: 32px;
  float: none;
  border-left: 3px solid var(--color--primary);
  margin: 0 0 var(--gutter) 0;
  position: relative;

  p:last-of-type {
    margin-bottom: 0;
  }

  cite {
    font-size: 16px;
    line-height: 1.5625;
    display: block;
    text-indent: 0;
    color: var(--color--primary);
    margin-top: 12.5px;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
      width: 12px;
      height: 1px;
      background: var(--color--primary);
    }
  }

  @include bp-medium {

    // Left

    &.blockquote--left {
      float: left;
      max-width: 40%;
      margin: 0 var(--gutter) var(--gutter) 0;
      border-left-width: 0;
      border-right: 3px solid var(--color--primary);
    }

    // Right

    &.blockquote--right {
      float: right;
      max-width: 40%;
      margin: 0 0 var(--gutter) var(--gutter);
    }
  }
}

// Maintains logical order for asides floated right @m

@include bp-medium {
  .u-flex--m {
    .blockquote--right {
      flex-shrink: 0;
      align-self: flex-start;
    }
  }
}