/* ==========================================================================
   Tools
     > Elevation
   ========================================================================== */

/*

This is a Sass conversion of a lovely mixin which helps you to add depth to elements according to Material Design.
Material Design Spec: http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
Original article: https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
Original Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)

*/

@mixin shadow($level: 1) {
  @if $level == 0 {
    box-shadow: none !important;
  }
  @if $level == 1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  @else if $level == 2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level == 3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.15);
  }
  @else if $level == 4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.2);
  }
  @else if $level == 5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
  @else if $level == 8 {
    box-shadow: 0 19px 96px rgba(0,0,0,0.2), 0 15px 96px rgba(0,0,0,0.2);
  }
}
