/* ==========================================================================
Components
  > Articles 
========================================================================== */

.c-article {
  position: relative;
  margin-bottom: var(--gutter);
}

// Header - Main

.c-article__header--main {
  margin-top: -8px;
  margin-bottom: 48px;
}

.c-article__header {
  margin-inline: auto;
  margin-bottom: 40px !important;

  p:last-child {
    margin-bottom: 0;
  }

  @include bp-medium {
    margin-bottom: 80px !important;
  }
}

// Footer

.c-article__footer {
  margin-top: var(--spacer);
}

// Standard List

.c-article-item {
  margin-bottom: 40px;
  clear: both;
  position: relative;
  // max-width: 736px;

  @include bp-small {
    min-height: 200px;

    &.no-image {
      padding-right: 0px;
      min-height: 0px;
    }
  }

  .c-article-item__image {
    display: block;

    @include bp-small {
      float: left;
      width: var(--thumb__width);
      height: var(--thumb__height);
      margin-right: var(--gutter);

      // RHS e.g. News Listing

      &.c-img--right--sm {
        float: right;
        margin-right: 0;
        margin-left: var(--gutter);
      }
    }

    .c-thumb {
      max-height: 300px;

      @include bp-small {
        max-height: auto;
      }
    }

    .c-thumb a::after {
      display: none;
    }
  }

  .c-article-item__content {
    .meta {
      .category {
        display: inline-block;
        margin: 0;

        span {
          margin-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 16px;
          border-right: 1px solid #ccc;

          &:last-of-type {
            border-right: 0;
          }
        }
      }
    }

    .author,
    .meta {
      font-size: 0.875rem;

      span {
        color: var(--color--primary);
        font-weight: 500;
        margin-right: 1.5rem;
      }
    }

    .author {
      margin-bottom: 2px;
    }

    .meta {
      margin-bottom: 18px;
    }

    p {
      margin-top: 12px;
      margin-bottom: 0;
    }
  }
}

// Suggested

.c-articles--suggested {
  margin-bottom: var(--gutter);

  .c-articles__header {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    background: #eaeff6;
    margin: 0;
    padding: 4px 16px;
  }

  .c-article--suggested {
    border: none;
    padding-bottom: 0;
    margin-bottom: -2px;

    .c-article-item__content {
      border: 2px solid #eaeff6;
      padding: 24px;

      p {
        margin-top: 12px;
        margin-bottom: 0;
      }
    }
  }
}

.single-meta {
  margin-bottom: 2rem;

  .date {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    background: var(--color--primary);
    display: inline-block;
    color: #fff;
    padding: 0.1rem 0.5rem;
  }

  .category {
    display: inline-block;
    margin: 0;

    span {
      margin-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 16px;
      border-right: 1px solid #ccc;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}

// Meta - Cats (below date @max-s)

@include bp-max-small {
  .c-meta__cats {
    clear: left;
    position: relative;
    top: 2px;
  }
}

// Meta - Cats (below date @l if in aside)

@include bp-large {
  aside {
    .c-meta__cats {
      clear: left;
      position: relative;
      top: 2px;
    }
  }
}

// Author Pod

.c-author-pod {
  width: 100%;
  max-width: 626px;
  position: relative;
  margin-top: 3rem;
  margin-bottom: 0;
  @include clearfix();

  h4 {
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 18px;
    line-height: 1.4;
  }

  .c-author__img__hold {
    width: 100px;
    height: 100px;
    margin-bottom: var(--gutter);

    .c-author__img {
      @include fluid-aspect(1 1);

      img {
        border-radius: 100%;
      }
    }

    @include bp-small {
      position: absolute;
      left: 0;
      top: 8px;
    }
  }

  @include bp-small {
    padding-left: calc(100px + var(--gutter));
  }
}

// List Small
.c-article-small {
  margin-bottom: 1.5rem;

  .title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    line-height: 1.6rem;

    a {
      color: var(--color--secondary);
    }
  }
}

.has-calendar {
  padding-left: 90px;
  position: relative;
}

.has-calendar .calendar {
  position: absolute;
  top: 0.4rem;
  left: 0;
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: var(--global__radius);
  text-align: center;
  padding: 5px;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
}

.has-calendar .calendar .month {
  font-size: 16px;
  display: block;
  margin: auto;
  font-weight: bold;
  color: red;
  margin-top: -5px;
}

// Meta

.c-meta {
  display: block;
  font-size: 14px;
  margin-bottom: 1rem;
  color: #888;

  span {
    margin-right: 0.8rem;
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  .tag {
    background: var(--color--grey--light);
    padding: 0.2rem 0.5rem;
    color: var(--color--secondary);
  }

  .status {
    color: var(--color--secondary);
  }
}

// Events

.c-event-list .col:last-child {
  margin-bottom: 0;
}

.c-event {
  @include t-event;
}

.c-event--date {
  @include t-figure--calendar;

  .day {
    display: none;
  }

  @include bp-small {
    @include t-figure--calendar__detail;
  }
}

// Filters

.c-filters {
  @include clearfix();
  margin-top: 32px;
  margin-bottom: 8px;

  .c-filter__input-hold {
    position: relative;

    input {
      border-color: #979797;
      padding-right: calc(var(--tap-target__size) + 8px);
    }

    button {
      @include t-reset--btn;
      position: absolute;
      top: 50%;
      right: 1px;
      padding-right: 8px;
      width: var(--tap-target__size);
      height: var(--tap-target__size);
      transform: translateY(-50%);

      span {
        font-size: 13px;
      }
    }
  }

  .c-checkbox {
    padding-top: 8px;
  }
}

// Header

main article > header {
  > :first-child {
    margin: 0;
  }

  > *:not(:first-child) {
    margin: 24px 0 0;
  }

  // Restricted

  @include bp-medium-large {
    &.c-article__header--restricted {
      width: 75%;
    }
  }
}

// Footer

main article > footer {
  // Restricted

  @include bp-medium-large {
    &.c-article__footer--restricted {
      width: 75%;
    }
  }
}

// Header & Footer exceptions

@include bp-large {
  .l6--adjust {
    .c-article__header--restricted,
    .c-article__footer--restricted {
      width: 100%;
    }
  }
}

@include bp-xlarge {
  .xl6--adjust {
    .c-article__header--restricted,
    .c-article__footer--restricted {
      width: 100%;
    }
  }
}
