/* ==========================================================================
   Tools
     > Themes
   ========================================================================== */

// Dark bgs

@mixin t-theme--bg-dark--surface--light {
  * {
    color: #fff;

    &:focus-visible {
      outline-color: #fff;
    }
  }

  a:hover {
    color: #fff !important;
  }

  .c-btn {
    color: var(--color--primary);
    background-color: #fff;

    &:hover {
      color: var(--color--text) !important;
      background-color: rgba(255, 255, 255, 0.86);
    }

    &--outline {
      color: #fff;
      background-color: transparent;
      border-color: #fff;

      &:hover {
        color: var(--color--primary);
        background-color: #fff;
      }
    }
  }

  .fa-file-pdf {
    color: #fff;
  }
}
