/* ==========================================================================
   #NAVIGATION - ADA MOBILE MENU
   ========================================================================== */

.nav {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** Hamburger button */
.menu-toggle-button {
  background: none;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 20px;
}

.menu-toggle-button .fas {
  width: 20px;
}

/** Mobile menu */
#mobile-menu-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;

  &:before {
    display: none;
  }

  @include bp-xlarge {
    display: none !important;
  }

  .c-modal__content {
    width: 80%;
    max-width: 350px;
  }
}

/** Close button */
.close-button {
  background: white;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  position: absolute;
  right: 0;
  width: var(--tap-target__size);
  height: var(--tap-target__size);
  z-index: 2;

  &:focus-visible {
    outline: none;
    background-color: var(--color--primary--lightest);
  }

  @media (hover) {
    &:hover {
      background-color: var(--color--primary--lightest);
    }
  }
}

.close-button .fas {
  width: 20px;
}

.menu-container {
  position: relative;
  height: calc(100% - 48px);
  z-index: 1;
  margin-top: 0;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.menu-container-overflow {
  height: 100%;
  padding-bottom: 50px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ada-menu {
  list-style: none;
  margin: 0;
  z-index: 2;
  width: 100%;

  .c-nav__divider {
    border-top: 1px solid var(--color--grey--light);
    margin: 10px 0;

    a {
      margin-top: 16px;
      color: var(--color--text);
      font-size: 14px;
    }
    ~ li {
      a,
      button {
        color: var(--color--text);
        font-size: 14px;
      }
    }
  }
}

.ada-menu li a,
.ada-menu li button {
  position: relative;
  display: block;
  width: 100%;
  padding: 13px 40px 13px 20px;
  font-size: 16px;
  line-height: 22px;
  color: var(--color--primary);
  text-decoration: none;
  text-align: left;
  background: #fff;
  border: 0;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    outline: none;
    background: var(--color--primary--lightest);
  }
}

.ada-menu li {
  a {
    display: flex;
    align-items: center;
    gap: 0 8px;
    padding-right: 24px;

    .fa-lock {
      font-size: 14px;
      margin-left: auto;
      color: var(--color--text);
    }
  }

  button {
    .far {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      font-size: 18px;
      color: var(--color--text);
    }
  }
}

/** Back button on level 1 menus */

.ada-menu li button.back-button {
  padding-left: 3rem;
  color: var(--color--text);

  .far {
    right: auto;
    left: 20px;
    transform: translateY(-50%);
  }

  &:focus-visible,
  &:hover {
    outline: none;
    background: var(--color--primary--lightest);
  }
}

/** Level 0 (top level) menu */
.menu-level-0 {
  position: absolute;
  left: 0;
  width: 100%;
}

/** Level 1 menu */
.menu-level-1 {
  position: absolute;
  left: 350px;
  width: 100%;
}

.menu-level-0 .ada-menu {
  display: block;
}

.menu-level-1 .ada-menu {
  display: none;
}

/** Level 2 menu */

.menu-level-2 {
  position: absolute;
  left: 350px;
  width: 100%;
}

.menu-level-2 .ada-menu {
  display: none;
}

/** Level 3 menu */

.menu-level-3 {
  position: absolute;
  left: 350px;
  width: 100%;
}

.menu-level-3 .ada-menu {
  display: none;
}

// Prevent page scroll

@media (max-width: 1279px) {
  .is--menu--mobile {
    body {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    .c-modal--menu {
      &:before {
        display: none;
      }
      .c-modal__dialog {
        transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
        transition-duration: 640ms;

        .c-modal__content {
          padding: 0;
          background: transparent;
        }
      }
    }

    &.mobile-dialog--active {
      .c-modal--menu {
        .c-modal__dialog {
          transform: translateX(0) !important;
        }
      }

      .c-modal__scrim {
        opacity: 1;
      }
    }
  }

  .c-modal__scrim {
    transition: opacity 320ms ease-in;
    background: rgba(0, 0, 0, 0.32);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
}

.c-menu__footer {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding-inline: 20px;
  background: #fff;
  border-top: 1px solid var(--color--grey--light);
  z-index: 1;

  .c-utility-menu {
    padding-left: 0;
  }

  .c-footer-social {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin: 0;

    li {
      list-style-type: none;

      a {
        display: block;
        width: 32px;
        height: 32px;
        text-align: center;
        color: var(--color--primary);
      }
    }
  }
}
