.c-step {
  padding-bottom: 1.5rem;
  h3,
  .h3 {
    padding: 16px;
    font-size: 24px;
    &::before {
      content: counter(slide, lower-alpha);
      display: none;
      padding-top: 8px;
      padding-bottom: 24px;
      font-size: 32px;
      font-weight: 700;
    }
  }
  div {
    padding-inline: 16px;
    padding-top: 10px;
    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.3;
  }
}

.c-slider--steps {
  .slick-slide {
    counter-increment: slide;
    .c-step {
      h3,
      .h3 {
        color: #fff;
        &::before {
          display: block;
        }
      }
    }

    @for $i from 1 through 11 {
      &:nth-child(#{$i}) {
        background-color: var(--color--step#{$i}--bg);
        border: 1px solid var(--color--step#{$i});
        h3 {
          background-color: var(--color--step#{$i});
        }
      }
    }
  }
}
