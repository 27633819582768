/* ==========================================================================
Elements
  > html
========================================================================== */

html {
  background: #fff;
  color: var(--color--text);
  font-family: var(--font--body);
  font-weight: 400;
  font-size: var(--font__size--base);
  line-height: var(--line__height--base);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background: #fff;
}

:focus-visible {
  outline: 2px solid var(--color--primary--dark);
  outline-offset: 2px;
}
