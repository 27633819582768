/* ==========================================================================
   Tools
     > Tables
   ========================================================================== */

// Responsive

@mixin t-table--responsive {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  margin: 0;
  padding: 0;

  .c-table,
  table {
    width: 100%;
    margin-bottom: 0;
  }
}

// Compact

@mixin t-table--compact() {
  tr,
  th,
  td {
    padding: 8px 16px;
  }
}

// Striped

@mixin t-table--striped() {
  // th,
  // td {
  //   border: none;
  // }

  > tbody {
    > tr:nth-child(even) > {
      th,
      td {
        background-color: var(--color--primary--lightest);
      }
    }
  }
}

// Bordered

@mixin t-table--bordered() {
  > thead {
    color: var(--color--text);
    background-color: transparent;

    th {
      color: var(--color--primary);
    }
  }
}
